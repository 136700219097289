import { Button, DialogPosition } from '@ecdlink/ui';
import AlertModal from '../../../../components/dialog-alert/dialog-alert';
import {
  HealthCareWorkerDto,
  NOTIFICATION,
  RoleDefaultNameEnum,
  UserDto,
  useDialog,
  useNotifications,
} from '@ecdlink/core';
import { useMutation } from '@apollo/client';
import {
  DeactivateHealthCareWorker,
  DeactivateTeamLead,
  DeleteUser,
} from '@ecdlink/graphql';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

interface DeactivateUserProps {
  userData: UserDto;
  chwData: HealthCareWorkerDto;
  refetchUserData?: () => void;
  isTeamLead?: boolean;
  teamLeadId?: string;
  hcwId?: string;
  isAdministrator?: boolean;
}

export const DeactivateUser: React.FC<DeactivateUserProps> = ({
  userData,
  chwData,
  refetchUserData,
  isTeamLead,
  teamLeadId,
  hcwId,
  isAdministrator,
}) => {
  const dialog = useDialog();
  const { setNotification } = useNotifications();
  const [deactivateTeamLead] = useMutation(DeactivateTeamLead);
  const [deactivateHcw] = useMutation(DeactivateHealthCareWorker);
  const [deactivateAdminUser] = useMutation(DeleteUser);
  const history = useHistory();

  const handleDeactivateUser = useCallback(() => {
    if (isAdministrator) {
      deactivateAdminUser({
        variables: {
          id: userData?.id,
        },
      })
        .then(() => {
          refetchUserData();
          setNotification({
            title: 'Successfully deactivated Administrator!',
            variant: NOTIFICATION.SUCCESS,
          });
        })
        .catch((err) => {
          setNotification({
            title: 'Failed to deactivate Administrator',
            variant: NOTIFICATION.ERROR,
          });
        });
      history.push(`/users/admins`);
    } else if (isTeamLead) {
      deactivateTeamLead({
        variables: {
          teamLeadId: teamLeadId,
        },
      })
        .then(() => {
          refetchUserData();
          setNotification({
            title: 'Successfully deactivated Team Lead!',
            variant: NOTIFICATION.SUCCESS,
          });
        })
        .catch((err) => {
          setNotification({
            title: 'Failed to deactivate Team Lead',
            variant: NOTIFICATION.ERROR,
          });
        });
      history.push(`/users/team-leads`);
    } else {
      deactivateHcw({
        variables: {
          hcwId: hcwId,
        },
      })
        .then(() => {
          refetchUserData();
          setNotification({
            title: 'Successfully deactivated Health Care Worker!',
            variant: NOTIFICATION.SUCCESS,
          });
        })
        .catch((err) => {
          setNotification({
            title: 'Failed to deactivate Health Care Worker!',
            variant: NOTIFICATION.ERROR,
          });
        });
      history.push(`/users/health-care-worker`);
    }
  }, [
    deactivateAdminUser,
    deactivateHcw,
    deactivateTeamLead,
    hcwId,
    history,
    isAdministrator,
    isTeamLead,
    refetchUserData,
    setNotification,
    teamLeadId,
    userData?.id,
  ]);

  const deactivaterUser = async () => {
    dialog({
      position: DialogPosition.Middle,
      render: (onSubmit: any, onCancel: any) => (
        <AlertModal
          title={`Deactivate ${
            isTeamLead ? RoleDefaultNameEnum.TeamLead : RoleDefaultNameEnum.CHW
          }`}
          message={`You are about to deactivate ${
            chwData?.user?.fullName ?? userData?.fullName
          }`}
          btnText={['Yes, deactivate', 'No, Cancel']}
          onCancel={onCancel}
          onSubmit={() => {
            onSubmit();
            handleDeactivateUser();
          }}
        />
      ),
    });
  };

  return (
    <div>
      <Button
        className={'w-full rounded-2xl lg:w-56'}
        type="outlined"
        color="tertiary"
        onClick={deactivaterUser}
        icon="TrashIcon"
        text="Deactivate user"
        textColor="tertiary"
      ></Button>
    </div>
  );
};
