import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../components/auth/login/login';
import Register from '../components/auth/register-user/register';
import Shell from '../containers/shell/shell';
import ContentManagement from '../pages/content-management/content-management';
import Dashboard from '../pages/dashboard/dashboard';
import Documents from '../pages/documents/documents';
import { Preview } from '../pages/preview/preview';
import Profile from '../pages/profile/profile';
import ViewUser from '../pages/view-user/view-user';
import Reports from '../pages/reports/reports';
import Roles from '../pages/roles/roles';
import Settings from '../pages/settings/settings';
import GeneralSettingsView from '../pages/settings/sub-pages/general-settings/general-settings';
import NavigationSetup from '../pages/settings/sub-pages/navigation/navigation';
import Theme from '../pages/settings/sub-pages/theme/theme';
import StaticData from '../pages/static-data/static-data';
import AttendingReasonsView from '../pages/static-data/sub-pages/attending-reasons/attending-reasons';
import EducationLevelView from '../pages/static-data/sub-pages/education-levels/education-levels';
import GenderView from '../pages/static-data/sub-pages/gender/gender';
import GrantView from '../pages/static-data/sub-pages/grants/grants';
import LanguageView from '../pages/static-data/sub-pages/language/language';
import ProvinceView from '../pages/static-data/sub-pages/provinces/provinces';
import RaceView from '../pages/static-data/sub-pages/race/race';
import ReasonForLeavingView from '../pages/static-data/sub-pages/reason-for-leaving/reason-for-leaving';
import RelationsView from '../pages/static-data/sub-pages/relations/relations';
import ApplicationAdmins from '../pages/users/sub-pages/application-admins/application-admins';
import Children from '../pages/users/sub-pages/children/children';
import Coaches from '../pages/users/sub-pages/coaches/coaches';
import HealthCareWorkers from '../pages/users/sub-pages/health-care-worker/health-care-worker';
import Infants from '../pages/users/sub-pages/infant/infant';
import Mothers from '../pages/users/sub-pages/mother/mother';
import Practitioners from '../pages/users/sub-pages/practitioners/practitioners';
import Franchisors from '../pages/users/sub-pages/franchisors/franchisors';
import Users from '../pages/users/users';
import Clinics from '../pages/users/sub-pages/clincs/clinics';
import TeamLeads from '../pages/users/sub-pages/team-leads/team-leads';
import ForgotPassword from '../components/auth/forgot-password/forgot-password';
import ResetPassword from '../components/auth/reset-password/reset-password';
import ApplicationUsers from '../pages/users/sub-pages/application-users/application-users';
import UploadBulkUser from '../pages/upload-bulk-users/upload-bulk-users';
import TermsPage from '../pages/terms/terms';
import Messaging from '../pages/messaging/messaging';
import MessagePanel from '../pages/messaging/components/message-panel';
import MessageList from '../pages/messaging/components/messaging-list';
import { ClinicsMainPage } from '../pages/clinics/clinics';
import ClinicsSubPage from '../pages/clinics/sub-pages/clinics-sub-page/clinics-sub-page';
import DistrictsSubPage from '../pages/clinics/sub-pages/districts-sub-page/districts-sub-page';
import SubDistrictsSubPage from '../pages/clinics/sub-pages/sub-districts-sub-page/sub-districts-sub-page';
import { ViewClinicReport } from '../pages/clinics/components/view-clinic-report/view-clinic-report';
import RegisterTeamLead from '../components/auth/register-team-lead/register-team-lead';
import ROUTES from './app.routes-constants';
import { Referrals } from '../pages/referrals/referrals';
import { ViewReferralDetail } from '../pages/referrals/view-referral-detail/view-referral-detail';
import { EditBackReferral } from '../pages/referrals/edit-back-referral/edit-back-referral';
import LoginTeamLead from '../components/auth/login-team-lead/login-team-lead';
import TeamLeadForgotPassword from '../components/auth/team-lead-forgot-password/team-lead-forgot-password';
import { Leagues } from '../pages/clinics/leagues/leagues';
import { LeagueDetails } from '../pages/clinics/leagues/view-league-season/league-performance/league-details/league-details';
import { AddLeagues } from '../pages/clinics/leagues/view-league-season/league-management/add-leagues';
import VerifyPhoneNumber from '../components/auth/verify-phone-number/verify-phone-number';
import TLMeetings from '../pages/tl-meetings/tl-meetings';
import { SeeReports } from '../pages/tl-meetings/components/see-reports';
import { NotificationsView } from '../notifications/notificationsView';
import { LeaguePerformance } from '../pages/clinics/leagues/view-league-season/league-performance/league-performance';
import { LeagueManagement } from '../pages/clinics/leagues/view-league-season/league-management/league-management';
import { TeamMeetingsRoot } from '../pages/team-meetings/team-meetings-root';
import { EditTopics } from '../pages/tl-meetings/components/edit-topics/edit-topics';
import { ViewReport } from '../pages/tl-meetings/components/view-report/view-report';
import { HealthCareWorkerOptedOut } from '../pages/health-care-worker/health-care-worker-opted-out';
import { TLLeagues } from '../pages/clinics/leagues/tl-leagues';
import { useApolloClient } from '@apollo/client';
import { GetTenantContext, TenantModel, TenantType } from '@ecdlink/graphql';
import StatsReporting from '../pages/reports/stats-reporting';

const PublicRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path={ROUTES.ROOT_TEAM_LEAD} component={LoginTeamLead} />
      <Route exact path="/register/:resetToken" component={Register} />
      <Route
        exact
        path={ROUTES.TEAM_LEAD_REGISTER}
        component={RegisterTeamLead}
      />
      <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route
        exact
        path={ROUTES.VERIFY_PHONE_NUMBER}
        component={VerifyPhoneNumber}
      />
      <Route
        exact
        path={ROUTES.TEAM_LEAD_RESET_PASSWORD}
        component={TeamLeadForgotPassword}
      />
      <Route exact path={ROUTES.RESET} component={ResetPassword} />
      <Route path={`/ecd-terms`} component={TermsPage}></Route>
    </Switch>
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={Shell}></Route>
    </Switch>
  );
};

const ReferralRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.REFERRALS.ROOT} component={Referrals}></Route>
      <Route
        exact
        path={ROUTES.REFERRALS.VIEW_REFERRAL_DETAIL.ROOT}
        component={ViewReferralDetail}
      ></Route>
      <Route
        exact
        path={ROUTES.REFERRALS.VIEW_REFERRAL_DETAIL.EDIT_BACK_REFERRAL}
        component={EditBackReferral}
      ></Route>
    </Switch>
  );
};

const TlMeetingsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.TL_MEETINGS.MEETINGS} component={TLMeetings}></Route>
      <Route
        path={ROUTES.TL_MEETINGS.REPORTS.SEE_REPORTS}
        component={SeeReports}
      ></Route>
      <Route
        path={ROUTES.TL_MEETINGS.REPORTS.VIEW_REPORT}
        component={ViewReport}
      ></Route>
      <Route
        path={ROUTES.TL_MEETINGS.EDIT_TOPICS}
        component={EditTopics}
      ></Route>
    </Switch>
  );
};

const AuthRoutes: React.FC = () => {
  const apolloClient = useApolloClient();

  const data =
    apolloClient.readQuery<{ tenantContext?: TenantModel }>({
      query: GetTenantContext,
    }) || {};

  const isGrowGreatTenant =
    data?.tenantContext?.tenantType === TenantType.ChwConnect;

  return (
    <Switch>
      <Route path={`/dashboard`} component={Dashboard}></Route>
      {!isGrowGreatTenant && (
        <Route path={`/settings`} component={Settings}></Route>
      )}
      <Route path={`/data`} component={StaticData}></Route>
      <Route path={ROUTES.PROFILE} component={Profile}></Route>
      <Route path={`/upload-users`} component={UploadBulkUser}></Route>
      <Route path={`/users`} component={Users}></Route>
      <Route path={ROUTES.CLINICS.ROOT} component={ClinicsMainPage}></Route>
      <Route path={ROUTES.TL_MEETINGS.ROOT} component={TLMeetings}></Route>
      <Route path={ROUTES.TEAM_MEETINGS} component={TeamMeetingsRoot}></Route>
      <Route path={ROUTES.REFERRALS.ROOT} component={ReferralRoutes}></Route>
      <Route path={`/documents`} component={Documents}></Route>
      <Route path={`/content-management`} component={ContentManagement}></Route>
      {!isGrowGreatTenant && (
        <Route path={`/Reports`} component={Reports}></Route>
      )}
      <Route path={`/roles`} component={Roles}></Route>
      <Route path={`/messaging`} component={Messaging}></Route>
      <Route path={ROUTES.TEAM_LEAD_LEAGUES} component={TLLeagues}></Route>
      <Route
        path={ROUTES.NOTIFICATIONS_VIEW}
        component={NotificationsView}
      ></Route>
      <Route
        path={ROUTES.HEALTH_CARE_WORKER.OPTED_OUT}
        component={HealthCareWorkerOptedOut}
      ></Route>
      <Route path={ROUTES.STATS_REPORTING} component={StatsReporting}></Route>
    </Switch>
  );
};

const MessageRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`/messaging/list-messages`} component={MessageList}></Route>
      <Route path={`/messaging/view-message`} component={MessagePanel}></Route>
    </Switch>
  );
};

const SettingsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`/settings/theme`} component={Theme}></Route>
      <Route path={`/settings/general`} component={GeneralSettingsView}></Route>
      <Route path={`/settings/preview`} component={Preview}></Route>
      <Route path={`/settings/navigation`} component={NavigationSetup}></Route>
    </Switch>
  );
};

const ClinicsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path={ROUTES.CLINICS.ALL_CLINICS}
        component={ClinicsSubPage}
      ></Route>
      <Route
        path={ROUTES.CLINICS.VIEW_CLINICS}
        component={ViewClinicReport}
      ></Route>
      <Route path={`/clinics/districts`} component={DistrictsSubPage}></Route>
      <Route
        path={`/clinics/sub-districts`}
        component={SubDistrictsSubPage}
      ></Route>
      <Route
        exact
        path={ROUTES.CLINICS.LEAGUES.ROOT}
        component={Leagues}
      ></Route>
      <Route
        exact
        path={ROUTES.CLINICS.LEAGUES.VIEW_LEAGUE_SEASON.ROOT}
        component={LeaguePerformance}
      ></Route>
      <Route
        exact
        path={ROUTES.CLINICS.LEAGUES.LEAGUE_MANAGEMENT.ROOT}
        component={LeagueManagement}
      ></Route>
      <Route
        exact
        path={ROUTES.CLINICS.LEAGUES.VIEW_LEAGUE_SEASON.LEAGUE_DETAILS}
        component={LeagueDetails}
      ></Route>
      <Route
        exact
        path={ROUTES.CLINICS.LEAGUES.VIEW_LEAGUE_SEASON.ADD_LEAGUES}
        component={AddLeagues}
      ></Route>
    </Switch>
  );
};

const UserRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`/users/roles`} component={Roles}></Route>
      <Route path={ROUTES.USERS.ALL_ROLES} component={ApplicationUsers}></Route>
      <Route path={`/users/roles`} component={Roles}></Route>
      <Route path={ROUTES.VIEW_USERS} component={ViewUser}></Route>
      <Route path={ROUTES.USERS.ADMINS} component={ApplicationAdmins}></Route>
      <Route path={`/users/franchisors`} component={Franchisors}></Route>
      <Route path={`/users/coaches`} component={Coaches}></Route>
      <Route path={`/users/practitioners`} component={Practitioners}></Route>
      <Route path={`/users/clinics`} component={Clinics}></Route>
      <Route path={ROUTES.USERS.TEAM_LEADS} component={TeamLeads}></Route>
      <Route
        path={ROUTES.USERS.HEALTH_CARE_WORKERS}
        component={HealthCareWorkers}
      ></Route>
      <Route path={`/users/mother`} component={Mothers}></Route>
      <Route path={`/users/infant`} component={Infants}></Route>
      <Route path={`/users/children`} component={Children}></Route>
    </Switch>
  );
};

const StaticDataRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`/data/sex`} component={GenderView}></Route>
      <Route path={`/data/race`} component={RaceView}></Route>
      <Route path={`/data/languages`} component={LanguageView}></Route>
      <Route path={`/data/provinces`} component={ProvinceView}></Route>
      <Route path={`/data/grants`} component={GrantView}></Route>
      <Route
        path={`/data/education-levels`}
        component={EducationLevelView}
      ></Route>
      <Route
        path={`/data/attending-reasons`}
        component={AttendingReasonsView}
      ></Route>
      <Route path={`/data/relations`} component={RelationsView}></Route>
      <Route
        path={`/data/reasons-for-leaving`}
        component={ReasonForLeavingView}
      ></Route>
    </Switch>
  );
};

export {
  PublicRoutes,
  AuthRoutes,
  MainRoutes,
  SettingsRoutes,
  StaticDataRoutes,
  UserRoutes,
  MessageRoutes,
  ClinicsRoutes,
  TlMeetingsRoutes,
};
