import { useQuery } from '@apollo/client';
import {
  GetAllPortalClinics,
  settingDashboardTeamLead,
} from '@ecdlink/graphql';
import { useState, useEffect } from 'react';
import { LoadingSpinner, TabItem, TabList } from '@ecdlink/ui';
import { addMonths } from 'date-fns';

const getEndOfQuarterMonthName = (date: Date): string => {
  // Get the current month (0 = January, 11 = December)
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  // Determine the end month of the quarter
  let endMonth: Date | undefined = undefined;

  if (dateMonth <= 2) {
    // Q1: Jan, Feb, Mar
    endMonth = new Date(dateYear, 2, 1); // March
  } else if (dateMonth <= 5) {
    // Q2: Apr, May, Jun
    endMonth = new Date(dateYear, 5, 1); // June
  } else if (dateMonth <= 8) {
    // Q3: Jul, Aug, Sep
    endMonth = new Date(dateYear, 8, 1); // September
  } else {
    // Q4: Oct, Nov, Dec
    endMonth = new Date(dateYear, 11, 1); // December
  }

  // Return the name of the month
  return endMonth?.toLocaleString('default', { month: 'long' });
};

export default function GrafanaDashboard() {
  const [selectedClinic, setSelectedClinic] = useState<TabItem | undefined>(
    undefined
  );
  const [dashboardUrlTemplate, setDashboardUrlTemplate] = useState<string>();
  const [dashboardUrl, setDashboardUrl] = useState<string>();
  const [navigation, setNavigation] = useState<TabItem[]>([]);

  const { data: settingsData } = useQuery(settingDashboardTeamLead, {
    fetchPolicy: 'cache-and-network',
  });

  // INFO: Same endpoint because the backend filters the clinics based on the user's role
  const { data, loading } = useQuery(GetAllPortalClinics, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data || !data.allPortalClinics) return;

    const clinics = data.allPortalClinics;

    const navigation =
      clinics?.map(
        (clinic): TabItem => ({
          title: clinic?.name ?? '',
          initActive: false,
          id: clinic?.id,
        })
      ) ?? [];

    setNavigation(navigation);
    setSelectedClinic(navigation.length >= 1 ? navigation[0] : undefined);
  }, [data]);

  useEffect(() => {
    if (!settingsData || !settingsData?.settings?.Dashboards?.TeamLead) return;
    setDashboardUrlTemplate(settingsData.settings.Dashboards.TeamLead);
  }, [settingsData]);

  useEffect(() => {
    if (!selectedClinic || !dashboardUrlTemplate) {
      setDashboardUrl('');
    } else {
      const lastQuarterDate = addMonths(new Date(), -3);
      const quarterMonth = getEndOfQuarterMonthName(lastQuarterDate);

      const clinic = encodeURIComponent(selectedClinic.title);
      const quarter = encodeURIComponent(quarterMonth);

      const url = dashboardUrlTemplate
        .replace('{clinic}', clinic)
        .replace('{quarter}', quarter);
      setDashboardUrl(url);
    }
  }, [dashboardUrlTemplate, selectedClinic]);

  if (loading) {
    return (
      <div className="mt-16">
        <LoadingSpinner
          size="medium"
          backgroundColor="secondary"
          spinnerColor="adminPortalBg"
        />
      </div>
    );
  }

  if (navigation?.length >= 1) {
    return (
      <>
        <TabList
          className="w-full overflow-y-hidden bg-white"
          activeTabClassName=" bg-infoBb text-secondary border-b-secondary border-b-4 items-center flex justify-center"
          tabItems={navigation}
          tabSelected={(tab) => setSelectedClinic(tab)}
        />
        <div className="bg-adminPortalBg rounded-b-2xl p-4">
          <div className="hidden sm:block">
            <iframe
              title="Grafana Dashboard"
              className="dashboard-container w-full"
              src={dashboardUrl}
            ></iframe>
          </div>
        </div>
      </>
    );
  }
  return <></>;
}
