import {
  Alert,
  AlertType,
  ProfileAvatar,
  classNames,
  StatusChip,
  Breadcrumb,
  BreadcrumbProps,
  Typography,
  DatePicker,
  LoadingSpinner,
} from '@ecdlink/ui';
import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThumbUpIcon } from '@heroicons/react/solid';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GetHealthCareWorkerByUserId,
  GetTenantContext,
  GetUserById,
  GetHealthCareWorkerSummaryForPeriod,
  GetTeamLeadSummary,
  GetHealthCareWorkersOptedOutOfMonthlyMeeting,
  PortalHealthCareWorkerModel,
  PortalHealthCareWorkerModelSortInput,
  QueryHealthCareWorkersOptedOutOfMonthlyMeetingArgs,
  PortalUsersTlModel,
  TeamLeadById,
} from '@ecdlink/graphql';
import { format, sub, subDays } from 'date-fns';
import {
  UsersRolesTypeEnum,
  UsersRouteRedirectTypeEnum,
} from './view-user.types';
import { TeamLeadSummary } from './components/team-lead-summary/team-lead-summary';
import { TeamLeadMeetingReport } from './components/team-lead-meeting-reports/team-lead-meeting-reports';
import { ConenctUsage } from '../users/sub-pages/team-leads/team-leads.types';
import { SendInvite } from './components/send-invite/send-invite';
import { DeactivateUser } from './components/deactivate-user/deactivate-user';
import { HealthCareWorkerSummary } from './components/health-care-worker-summary/health-care-worker-summary';
import { HealthCareWorkerIssues } from './components/health-care-worker-issues/health-care-worker-issues';
import { HealthCareWorkerHighlights } from './components/health-care-worker-highlights/health-care-worker-highlights';
import { PersonalInfo } from './components/personal-info/personal-info';
import { TenantType } from '../../utils/constants';
import ROUTES from '../../routes/app.routes-constants';
import { useUserRole } from '../../hooks/useUserRole';
import { TeamLeadClinics } from './components/team-lead-clinics/team-lead-clinics';
import { RoleSystemNameEnum } from '@ecdlink/core';

const formatDate = (value: string | number | Date) => {
  try {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  } catch (e) {
    return 'N/A';
  }
};
const showNotification = (
  message: string,
  type: AlertType,
  icon?: ReactElement<any, string | JSXElementConstructor<any>>
) => {
  return (
    <Alert
      className="mx-20 mt-5 mb-3 rounded-md"
      message={message}
      type={type}
      customIcon={icon}
    />
  );
};

export function ViewUser(props: any) {
  const currentDate = new Date();
  const previousMonth = sub(currentDate, {
    months: 1,
  });
  const previousMonthEdited = sub(currentDate, {
    months: 2,
  });
  const previousMonthName = format(previousMonthEdited, 'MMMM');
  const currentMonthName = format(previousMonth, 'MMMM');
  const startDate1 = subDays(currentDate, 30);
  const endDate1 = currentDate;
  const hcwId = props?.location?.state?.hcwId;
  const isTeamLead =
    props.location.state?.component === UsersRouteRedirectTypeEnum?.teamLeads;
  const isAdministrator =
    props.location.state?.component === UsersRolesTypeEnum?.administrator;
  const isCHW =
    props.location.state?.component === UsersRouteRedirectTypeEnum?.chw;
  const [successNotification] = useState<boolean>(false);
  const { isTeamLead: isTeamLeadRole, isSuperAdmin: isSuperAdminRole } =
    useUserRole();

  const [startDate, setStartDate] = useState(startDate1);
  const [endDate, setEndDate] = useState(endDate1);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [userIsRegistered, setUserIsRegistered] = useState(false);

  let userId = localStorage.getItem('selectedUser');

  useEffect(() => {
    if (isCHW) {
    }
  });

  const paths: BreadcrumbProps['paths'] = isTeamLeadRole
    ? [
        { name: 'CHWs', url: ROUTES.USERS.HEALTH_CARE_WORKERS },
        { name: 'View user', url: '' },
      ]
    : [
        { name: 'Users', url: ROUTES.USERS.ALL_ROLES },
        ...(isAdministrator
          ? [{ name: 'Administrators', url: ROUTES.USERS.ADMINS }]
          : []),
        ...(isTeamLead
          ? [{ name: 'Team Leads', url: ROUTES.USERS.TEAM_LEADS }]
          : []),
        ...(isCHW
          ? [{ name: 'CHWs', url: ROUTES.USERS.HEALTH_CARE_WORKERS }]
          : []),
        ...(!isAdministrator && !isTeamLead && !isCHW
          ? [{ name: 'All roles', url: ROUTES.USERS.ALL_ROLES }]
          : []),
        { name: 'View user', url: '' },
      ];

  const { data, loading: loadingTenant } = useQuery(GetTenantContext, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    getChwById,
    { data: chwData, refetch: refetchCHW, loading: loadingChw },
  ] = useLazyQuery(GetHealthCareWorkerByUserId, {
    variables: {
      userId: '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: healthCareWorkersOptedOutOfMonthlyMeeting1, loading } =
    useQuery<{
      healthCareWorkersOptedOutOfMonthlyMeeting: PortalHealthCareWorkerModel[];
    }>(GetHealthCareWorkersOptedOutOfMonthlyMeeting, {
      fetchPolicy: 'cache-and-network',
      variables: {
        month: previousMonth.getMonth(),
        year: currentDate?.getFullYear(),
        order: [
          {
            dateInvited: 'DESC',
          },
        ] as PortalHealthCareWorkerModelSortInput,
      } as QueryHealthCareWorkersOptedOutOfMonthlyMeetingArgs,
      skip: !currentDate,
    });
  const { data: healthCareWorkersOptedOutOfMonthlyMeeting2 } = useQuery<{
    healthCareWorkersOptedOutOfMonthlyMeeting: PortalHealthCareWorkerModel[];
  }>(GetHealthCareWorkersOptedOutOfMonthlyMeeting, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: currentDate.getMonth(),
      year: currentDate?.getFullYear(),
      order: [
        {
          dateInvited: 'DESC',
        },
      ] as PortalHealthCareWorkerModelSortInput,
    } as QueryHealthCareWorkersOptedOutOfMonthlyMeetingArgs,
    skip: !currentDate,
  });

  const {
    data: userData,
    refetch: refetchUserData,
    loading: loadingUser,
  } = useQuery(GetUserById, {
    variables: {
      userId: props.location.state.userId ?? userId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const teamLeadId = isTeamLead
    ? userData?.userById?.id
    : props?.location?.state?.teamLeadId;

  const userOptOut1 =
    healthCareWorkersOptedOutOfMonthlyMeeting1?.healthCareWorkersOptedOutOfMonthlyMeeting?.find(
      (item) => item?.userId === userData?.userById?.id
    );
  const userOptOut2 =
    healthCareWorkersOptedOutOfMonthlyMeeting2?.healthCareWorkersOptedOutOfMonthlyMeeting?.find(
      (item) => item?.userId === userData?.userById?.id
    );

  const { data: teamLeadData, loading: loadingTeamLeadData } = useQuery<{
    teamLeadById: PortalUsersTlModel;
  }>(TeamLeadById, {
    variables: {
      teamLeadUserId: teamLeadId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !teamLeadId,
  });

  const isRegistered =
    props?.location?.state?.isRegistered ||
    chwData?.healthCareWorkerByUserId?.isRegistered;
  const connectUsage =
    props?.location?.state?.connectUsage ||
    chwData?.healthCareWorkerByUserId?.connectUsage ||
    teamLeadData?.teamLeadById?.user?.connectUsage;
  const connectUsageColor =
    props?.location?.state?.connectUsageColor ||
    chwData?.healthCareWorkerByUserId?.connectUsageColor ||
    teamLeadData?.teamLeadById?.user?.connectUsageColor;

  const [getHealthCareWorkerSummaryForPeriod, { data: summaryData }] =
    useLazyQuery(GetHealthCareWorkerSummaryForPeriod, {
      variables: {
        userId: '',
        healthCareWorkerId: '',
        startDate: '',
        endDate: '',
      },
      fetchPolicy: 'cache-and-network',
    });

  const isLoading = loadingTenant || loadingChw || loadingUser;

  useEffect(() => {
    getHealthCareWorkerSummaryForPeriod({
      variables: {
        userId: props.location.state.userId ?? userId,
        healthCareWorkerId:
          chwData?.healthCareWorkerByUserId?.user?.id ??
          props.location.state.userId ??
          userId,
        startDate: startDate?.[0]?.toISOString() ?? startDate?.toISOString(),
        endDate: endDate?.[1]?.toISOString() ?? endDate?.toISOString(),
      },
    });
  }, [
    chwData?.healthCareWorkerByUserId?.user?.id,
    endDate,
    getHealthCareWorkerSummaryForPeriod,
    props.location.state.userId,
    startDate,
    userId,
  ]);

  const [getTeamLeadSummary, { data: teamLeadSummary }] = useLazyQuery(
    GetTeamLeadSummary,
    {
      variables: {
        teamLeadId: teamLeadId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const clinicIds =
    props?.location?.state?.clinicIds ||
    teamLeadSummary?.teamLeadSummary?.clinics?.map((item) => item?.id);

  useEffect(() => {
    if (teamLeadId) {
      getTeamLeadSummary();
    }
  }, [getTeamLeadSummary, teamLeadId]);

  const teamLeadReportData = useMemo(
    () => teamLeadSummary?.teamLeadSummary,
    [teamLeadSummary?.teamLeadSummary]
  );

  useEffect(() => {
    props.location.state?.component === UsersRouteRedirectTypeEnum?.chw &&
      getChwById({
        variables: { userId: props.location.state.userId ?? userId },
      });
  }, [userId]);

  const isNotLockedOut = (user) => {
    if (!user) return true;
    return !user?.lockoutEnd || user?.lockoutEnd < new Date();
  };

  let isCHWRole = userData?.userById?.roles?.some(
    (role: any) => role.systemName === RoleSystemNameEnum.CHW
  );

  const getRoleStatusChip = (status: string) => {
    switch (status) {
      case UsersRouteRedirectTypeEnum?.chw:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour="tertiary"
              backgroundColour="tertiary"
              textColour="white"
              text={UsersRolesTypeEnum?.chw}
            />
          </div>
        );
      case UsersRouteRedirectTypeEnum?.teamLeads:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour="darkBlue"
              backgroundColour="darkBlue"
              textColour="white"
              text={UsersRolesTypeEnum?.teamLeads}
            />
          </div>
        );
      case UsersRolesTypeEnum?.administrator:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour="darkBlue"
              backgroundColour="darkBlue"
              textColour="white"
              text={UsersRolesTypeEnum?.administrator}
            />
          </div>
        );
      default:
        return (
          <div>
            <StatusChip
              className="self-cente py-2r ml-auto"
              borderColour="infoDark"
              backgroundColour="infoDark"
              textColour="white"
              text={UsersRolesTypeEnum?.user}
            />
          </div>
        );
    }
  };

  const getConnectUsageChip = (value: string) => {
    switch (value) {
      case ConenctUsage?.InvitationActive:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour={connectUsageColor}
              backgroundColour={connectUsageColor}
              textColour="white"
              text={connectUsage}
            />
          </div>
        );
      case ConenctUsage?.InvitationExpired:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour={connectUsageColor}
              backgroundColour={connectUsageColor}
              textColour="white"
              text={connectUsage}
            />
          </div>
        );
      case ConenctUsage?.LastOnlineOver6Months:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour={connectUsageColor}
              backgroundColour={connectUsageColor}
              textColour="white"
              text={connectUsage}
            />
          </div>
        );
      case ConenctUsage?.LastOnlineWithinPast6Months:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour={connectUsageColor}
              backgroundColour={connectUsageColor}
              textColour="white"
              text={connectUsage}
            />
          </div>
        );
      default:
        return (
          <div>
            <StatusChip
              className="ml-auto self-center py-2"
              borderColour={connectUsageColor}
              backgroundColour={connectUsageColor}
              textColour="white"
              text={connectUsage}
            />
          </div>
        );
    }
  };

  if (isLoading) {
    return (
      <LoadingSpinner
        size="medium"
        backgroundColor="secondary"
        spinnerColor="white"
      />
    );
  }

  return (
    <div className="flex flex-col">
      <Breadcrumb paths={paths} />
      {successNotification &&
        showNotification(
          'User Added Successfully! ',
          'success',
          <ThumbUpIcon className="h-10 w-10"></ThumbUpIcon>
        )}
      <div className="mt-9 mb-7 flex gap-7">
        <ProfileAvatar
          canChangeImage={false}
          dataUrl={
            userData?.userById?.profileImageUrl ||
            chwData?.healthCareWorkerByUserId?.user?.profileImageUrl
          }
          onPressed={() => {}}
          hasConsent
          size="header"
        />
        <div className="flex flex-col justify-center gap-4">
          <Typography
            type="h1"
            color="textMid"
            text={
              userData?.userById?.fullName ??
              chwData?.healthCareWorkerByUserId?.user?.fullName
            }
          />
          <div className="flex gap-2">
            {getRoleStatusChip(props.location.state?.component)}
            {(isTeamLead || isCHWRole) && getConnectUsageChip(connectUsage)}
          </div>
        </div>
      </div>
      {chwData &&
        chwData?.healthCareWorkerByUserId?.user?.roles?.map(
          (i: any, index: number) => {
            return (
              <div
                key={i.id}
                className={classNames(
                  i.name === RoleSystemNameEnum.CHW
                    ? 'bg-primary'
                    : 'bg-tertiary',
                  ' m-1 my-2 flex flex-row justify-center rounded-full py-1  px-3 text-xs text-white'
                )}
              >
                <p className="text-16">
                  {' '}
                  {i.name === RoleSystemNameEnum.CHW ? 'CHW' : i.name}
                </p>
              </div>
            );
          }
        )}

      {!isNotLockedOut(
        userData?.userById ?? chwData?.healthCareWorkerByUserId?.user
      ) && (
        <Alert
          className="mt-5 mb-3"
          message={`This user has been deactivated and cannot access ${data?.tenantContext.applicationName} App`}
          type="error"
        />
      )}
      {userOptOut1 && (
        <Alert
          className="mt-5 mb-3"
          title={`${userData?.userById?.firstName} opted out of GGG in ${previousMonthName}`}
          type="warning"
        />
      )}
      {userOptOut2 && (
        <Alert
          className="mt-5 mb-3"
          title={`${userData?.userById?.firstName} opted out of GGG in ${currentMonthName}`}
          type="warning"
        />
      )}
      <PersonalInfo
        userData={userData?.userById}
        chwData={chwData?.healthCareWorkerByUserId}
        isRegistered={isRegistered}
        component={props?.location?.state?.component}
        isTeamLead={isTeamLead}
        hcwId={hcwId}
        clinicId={props?.location?.state?.clinicId}
        refetchUserData={refetchUserData}
        refetchCHW={refetchCHW}
        isNotLockedOut={isNotLockedOut}
        isAdministrator={isAdministrator}
        userTypeToEdit={
          userData?.userById?.roles.length && userData?.userById?.roles[0].name
        }
      />
      {(isCHWRole ||
        props.location.state?.component === UsersRouteRedirectTypeEnum?.chw) &&
        // isRegistered &&
        data &&
        data.tenantContext &&
        data.tenantContext.tenantType === TenantType.ChwConnect && (
          <DatePicker
            selectsRange
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            shouldCloseOnSelect
            colour="secondary"
            textColour="white"
            hideCalendarIcon
            chevronIconColour="white"
            showChevronIcon
            isFullWidth={false}
            className="w-64 self-end rounded-xl"
            dateFormat={'d MMM yyyy'}
          />
        )}
      {(isCHWRole ||
        props.location.state?.component === UsersRouteRedirectTypeEnum?.chw) &&
        isRegistered && (
          <HealthCareWorkerSummary
            summaryData={summaryData?.healthCareWorkerSummaryForPeriod}
          />
        )}
      {(isCHWRole ||
        props.location.state?.component === UsersRouteRedirectTypeEnum?.chw) &&
        isRegistered && (
          <HealthCareWorkerIssues
            summaryData={summaryData?.healthCareWorkerSummaryForPeriod}
          />
        )}
      {(isCHWRole ||
        props.location.state?.component === UsersRouteRedirectTypeEnum?.chw) &&
        isRegistered && (
          <HealthCareWorkerHighlights
            summaryData={summaryData?.healthCareWorkerSummaryForPeriod}
          />
        )}
      {(isTeamLead ||
        props.location.state?.component ===
          UsersRouteRedirectTypeEnum?.teamLeads ||
        isTeamLead) && (
        <>
          <TeamLeadClinics clinicIds={clinicIds} />
        </>
      )}
      {(isTeamLead ||
        (props.location.state?.component ===
          UsersRouteRedirectTypeEnum?.teamLeads &&
          isRegistered)) && (
        <>
          <TeamLeadSummary
            teamLeadReportData={teamLeadReportData}
            clinicIds={clinicIds}
          />
          <TeamLeadMeetingReport
            teamLeadReportData={teamLeadReportData}
            clinicIds={clinicIds}
          />
        </>
      )}
      <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
        {((isNotLockedOut(
          userData?.userById ?? chwData?.healthCareWorkerByUserId?.user
        ) &&
          !isAdministrator &&
          !isTeamLeadRole) ||
          isSuperAdminRole) && (
          <div className="flex flex-col gap-2 lg:flex-row">
            {!isRegistered && !isAdministrator && (
              <SendInvite
                userData={userData?.userById}
                chwData={chwData?.healthCareWorkerByUserId}
                refetchUserData={refetchUserData}
              />
            )}
            <DeactivateUser
              userData={userData?.userById}
              chwData={chwData?.healthCareWorkerByUserId}
              refetchUserData={refetchUserData}
              isTeamLead={isTeamLead}
              teamLeadId={teamLeadId}
              hcwId={hcwId}
              isAdministrator={isAdministrator}
            />
          </div>
        )}

        <p className="ml-auto text-sm text-gray-600">
          User added to {data?.tenantContext.applicationName} App :{' '}
          {formatDate(
            chwData?.healthCareWorkerByUserId?.insertedDate ||
              userData?.userById?.insertedDate
          )}
        </p>
      </div>
    </div>
  );
}

export default ViewUser;
