import {
  Breadcrumb,
  BreadcrumbProps,
  Button,
  DatePicker,
  Typography,
} from '@ecdlink/ui';
import { useHistory, useLocation } from 'react-router';
import { ClinicsRouteState } from '../../main-view/admin-view/clinics.types';
import { CreateClinicPanel } from '../create-clinic-panel/create-edit-clinic-panel';
import { usePanel } from '@ecdlink/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GetClinicPointsData,
  GetClinicVisitReportData,
} from '@ecdlink/graphql';
import { ReportsDataChart } from './components/reportsDataChart';
import Pregnant from '../../../../../assets/gg-icons/pregnant.svg';
import Infant from '../../../../../assets/gg-icons/infant.svg';
import { ClientRegistration } from './components/client-registration';
import { PointsReportSummary } from './components/points-report-summary';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { format, lastDayOfMonth, startOfMonth, sub } from 'date-fns';
import { ViewClinicReportProps } from './view-clinic-report.types';
import ROUTES from '../../../../routes/app.routes-constants';
import { ReferralsRouteState } from '../../../referrals/types';
import { useUserRole } from '../../../../hooks/useUserRole';

export interface PointsReportSummaryDto {
  leagueRanking: number;
  pointsTotal: number;
  totalHCWs: number;

  momsTargetPerc: number;
  momsTargetPercColor: string;
  momsClinicHigherThan50Perc: string;
  momsTeamsBottomPerc: number;
  momsTeamsTopPerc: number;
  momsClinicLowerThan50Perc: number;
  momsTopTeamPerc: number;
  childrenTargetPerc: number;
  childrenTargetPercColor: string;
  childrenClinicHigherThan50Perc: string;
  childrenTeamsBottomPerc: number;
  childrenTeamsTopPerc: number;
  childrenClinicLowerThan50Perc: number;
  childrenTopTeamPerc: number;
}

export const ViewClinicReport = ({
  clinic: clinicFromProps,
  isFromTeamMeetings,
}: ViewClinicReportProps) => {
  const { isTeamLead } = useUserRole();
  const location = useLocation<ClinicsRouteState>();
  const history = useHistory();
  const panel = usePanel();
  const clinic = clinicFromProps || location?.state?.clinic;
  const today = useMemo(() => new Date(), []);
  const initialBefore30Days = sub(today, {
    days: 30,
  });
  const lastYear = sub(today, {
    years: 1,
  });
  const currentDateFormatted = format(today, 'MMMM y');
  const lastMonthDay = useMemo(() => lastDayOfMonth(today), [today]);
  const firstMonthDay = useMemo(() => startOfMonth(today), [today]);

  const [dateRange, setDateRange] = useState([initialBefore30Days, today]);
  const [startDate, endDate] = dateRange;

  const [fetchVisitInformation, { data: clinicReportData, loading }] =
    useLazyQuery(GetClinicVisitReportData, {
      fetchPolicy: 'cache-and-network',
      variables: {
        clinicId: clinic?.id,
        startDate: startDate,
        endDate: endDate,
      },
    });

  const { data: clinicPointsData } = useQuery(GetClinicPointsData, {
    fetchPolicy: 'cache-and-network',
    variables: {
      clinicId: clinic?.id,
    },
  });

  const dataFromClinicPointsData = clinicPointsData?.clinicPointsData;

  const clinicInformation = [
    ...(isTeamLead ? [] : [{ name: 'Unique ID:', value: clinic?.id }]),
    { name: 'Phone number:', value: clinic?.phoneNumber },
    { name: 'Address:', value: clinic?.siteAddress?.addressLine1 },
    { name: 'Sub-district:', value: clinic?.subDistrict?.name },
    { name: 'District:', value: clinic?.subDistrict?.district?.name },
    {
      name: 'Province:',
      value: clinic?.subDistrict?.district?.province?.description,
    },
  ];

  const paths: BreadcrumbProps['paths'] = [
    {
      url: ROUTES.CLINICS.ALL_CLINICS,
      name: 'Clinics',
    },
    {
      url: ROUTES.CLINICS.VIEW_CLINICS,
      name: 'View clinic',
    },
  ];

  useEffect(() => {
    if ((startDate && endDate) || isFromTeamMeetings) {
      if (isFromTeamMeetings) {
        fetchVisitInformation({
          fetchPolicy: 'cache-and-network',
          variables: {
            clinicId: clinic?.id,
            startDate: firstMonthDay,
            endDate: lastMonthDay,
          },
        });
        return;
      }
      fetchVisitInformation({
        fetchPolicy: 'cache-and-network',
        variables: {
          clinicId: clinic?.id,
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  }, [
    clinic?.id,
    endDate,
    fetchVisitInformation,
    firstMonthDay,
    isFromTeamMeetings,
    lastMonthDay,
    startDate,
  ]);

  const displayEditPanel = useCallback(() => {
    panel({
      noPadding: true,
      catchOnCancel: false,
      title: 'Edit a clinic',
      render: (onSubmit: any) => (
        <CreateClinicPanel
          key={`clinicPanelCreate`}
          isEdit={true}
          clinic={clinic}
          closeDialog={(clinicCreated: boolean) => {
            onSubmit();
          }}
        />
      ),
    });
  }, [clinic, panel]);

  useEffect(() => {
    if (location?.state?.openEditPanel) {
      displayEditPanel();
      history.replace({
        pathname: location.pathname,
        state: { ...location?.state, openEditPanel: false },
      });
    }
  }, [
    displayEditPanel,
    history,
    location.pathname,
    location?.state,
    location?.state?.openEditPanel,
  ]);

  return (
    <div>
      {!isFromTeamMeetings && <Breadcrumb paths={paths} />}
      <Typography
        className="mb-8 mt-12 truncate"
        type="h1"
        weight="bold"
        color="textMid"
        text={clinic?.name}
      />
      <div className="w-full rounded-2xl bg-white p-8">
        <div className="mb-4 flex items-center gap-2">
          <Typography
            className="truncate"
            type="h2"
            weight="bold"
            color="textMid"
            text={`Clinic information`}
          />
        </div>
        {clinicInformation.map((info, index) => (
          <div className="flex items-center gap-2" key={info.name + index}>
            <Typography
              className="truncate"
              type="h4"
              weight="bold"
              color="textMid"
              text={info.name}
            />
            <Typography
              className="truncate"
              type="body"
              color="textMid"
              text={info.value}
            />
          </div>
        ))}
        {!isTeamLead && (
          <div className="mt-4 flex w-full justify-end gap-2">
            <Button
              className="rounded-xl px-2"
              type="outlined"
              color="errorMain"
              textColor="tertiary"
              text="Copy unique ID"
              icon="DuplicateIcon"
              iconPosition="start"
              onClick={() => {
                navigator.clipboard.writeText(clinic?.id);
              }}
            />
            <Button
              className="rounded-xl px-2"
              type="filled"
              color="secondary"
              textColor="white"
              text="Edit"
              icon="PencilIcon"
              iconPosition="start"
              onClick={displayEditPanel}
            />
          </div>
        )}
      </div>
      {dataFromClinicPointsData && (
        <div className="mt-8">
          <PointsReportSummary
            dataFromClinicPointsData={dataFromClinicPointsData}
            clinic={clinic}
          />
        </div>
      )}

      <div className="bg-adminPortalBg mt-8 w-full">
        <div>
          <Typography
            type="h1"
            color="textDark"
            text={`% targets met so far this year`}
            align="left"
          />
          <Typography
            type="help"
            color="textDark"
            text={`October ${lastYear.getFullYear()} - ${format(
              today,
              'MMMM yyyy'
            )}`}
            align="left"
          />
        </div>
        {clinicPointsData?.clinicPointsData && (
          <div className="flex gap-x-4">
            <ReportsDataChart
              clinic={clinic}
              targetPerc={clinicPointsData?.clinicPointsData?.momsTargetPerc}
              targetPercColor={
                clinicPointsData?.clinicPointsData?.momsTargetPercColor
              }
              clinicHigherThan50Perc={
                clinicPointsData?.clinicPointsData?.momsClinicHigherThan50Perc
              }
              clinicLowerThan50Perc={
                clinicPointsData?.clinicPointsData?.momsClinicLowerThan50Perc
              }
              teamsBottomPerc={
                clinicPointsData?.clinicPointsData?.momsTeamsBottomPerc
              }
              teamsTopPerc={
                clinicPointsData?.clinicPointsData?.momsTeamsTopPerc
              }
              topTeamPerc={clinicPointsData?.clinicPointsData?.momsTopTeamPerc}
              targetRanking={
                clinicPointsData?.clinicPointsData?.momsRankingPerc
              }
              title={'Pregnant moms'}
              icon={Pregnant}
              key={Math.random()}
            />
            <ReportsDataChart
              clinic={clinic}
              targetPerc={
                clinicPointsData?.clinicPointsData?.childrenTargetPerc
              }
              targetPercColor={
                clinicPointsData?.clinicPointsData?.childrenTargetPercColor
              }
              clinicHigherThan50Perc={
                clinicPointsData?.clinicPointsData
                  ?.childrenClinicHigherThan50Perc
              }
              clinicLowerThan50Perc={
                clinicPointsData?.clinicPointsData
                  ?.childrenClinicLowerThan50Perc
              }
              teamsBottomPerc={
                clinicPointsData?.clinicPointsData?.childrenTeamsBottomPerc
              }
              teamsTopPerc={
                clinicPointsData?.clinicPointsData?.childrenTeamsTopPerc
              }
              topTeamPerc={
                clinicPointsData?.clinicPointsData?.childrenTopTeamPerc
              }
              targetRanking={
                clinicPointsData?.clinicPointsData?.childrenRankingPerc
              }
              title={'Children'}
              icon={Infant}
              key={Math.random()}
            />
          </div>
        )}
      </div>
      <div className="mt-8 flex w-full items-center justify-between">
        <Typography
          type="h1"
          weight="bold"
          color="textDark"
          text={
            isTeamLead && isFromTeamMeetings
              ? `Visit information: ${currentDateFormatted}`
              : `Visit information`
          }
          align="left"
        />
        {!isFromTeamMeetings && (
          <DatePicker
            isFullWidth={false}
            showChevronIcon
            hideCalendarIcon
            className="w-64 rounded-xl"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            maxDate={today}
            colour="secondary"
            textColour="white"
            onChange={(update) => {
              setDateRange(update);
            }}
            dateFormat={'d MMM yyyy'}
            onFocus={(e) => e.target.blur()}
          />
        )}
      </div>
      <ClientRegistration
        isLoading={loading}
        clinicReportData={clinicReportData?.clinicVisitReportData}
      />
      {isTeamLead && !isFromTeamMeetings && (
        <div className="mt-9 flex gap-4">
          <Button
            className="rounded-xl px-2"
            type="filled"
            color="secondary"
            textColor="white"
            text="See referrals"
            icon="ClipboardListIcon"
            onClick={() =>
              history.push(ROUTES.REFERRALS.ROOT, {
                clinicIds: [clinic?.id],
              } as ReferralsRouteState)
            }
          />
          <Button
            className="rounded-xl px-2"
            type="outlined"
            color="secondary"
            textColor="secondary"
            text="See CHWs"
            icon="UserGroupIcon"
            onClick={() => history.push(ROUTES.USERS.ROOT)}
          />
        </div>
      )}
    </div>
  );
};
